import React from 'react';
import { classNames } from 'utils';
import PropTypes from 'prop-types';
import { MenuIcon } from 'components/MegaMenu/subcomponents';
import './MenuLink.scss';

const MenuLink = ({ link }) => (
  <li>
    <a
      href={link.url || ''}
      className={classNames('mobile-menu__menu-link', {
        'mobile-menu__menu-link--icon': link.icon,
        'mobile-menu__menu-link--no-icon': !link.icon,
      })}
    >
      {link.icon && <MenuIcon type={link.icon} alt={`icon`} showMobile />}
      <span className={'mobile-menu__menu-link__text'}>{link.name}</span>
    </a>
  </li>
);

MenuLink.defaultProps = {
  link: {},
};

MenuLink.propTypes = {
  link: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string,
    alt: PropTypes.string,
  }),
};

export default MenuLink;
