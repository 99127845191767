import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import DesktopDropdown from '../DesktopDropdown';
import './DesktopMenuNavigationItem.scss';

const DesktopMenuNavigationItem = ({ item, isBannerActive }) => {
  if (item.url) {
    return (
      <li className={'menu-desktop__nav-link'}>
        <a href={item.url} className={'menu-desktop__nav-link--text'}>
          <span className={'menu-desktop__nav-link--text'}>{item.name}</span>
        </a>
      </li>
    );
  }

  return (
    <li className={'menu-desktop__nav-el'}>
      <span className={'menu-desktop__nav-el--text'}>{item.name}</span>
      <div
        className={classNames('menu-desktop__nav-dropdown', {
          'menu-desktop__nav-dropdown--banner': isBannerActive,
        })}
      >
        <div className={'menu-desktop__nav-dropdown--wrapper w-container'}>
          {item.categories?.map(category => {
            return <DesktopDropdown key={category.name} category={category} />;
          })}
        </div>
      </div>
    </li>
  );
};

DesktopMenuNavigationItem.defaultProps = {
  isBannerActive: false,
  item: {},
};

DesktopMenuNavigationItem.propTypes = {
  isBannerActive: PropTypes.bool,
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        links: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
            description: PropTypes.string,
            icon: PropTypes.string,
            image: PropTypes.string,
            alt: PropTypes.string,
          }),
        ),
        url: PropTypes.string,
      }),
    ),
  }),
};

export default DesktopMenuNavigationItem;
