import React from 'react';
import PropTypes from 'prop-types';
import { NewTopBanner } from 'components';
import { buttonConfigShape } from 'components/TopBanner/new';
import { NAVIGATION_ITEMS } from '../helpers';
import DesktopMenuNavigationItem from './DesktopMenuNavigationItem';
import CtaButton from '../../MenuCta';
import MenuLogo from '../../MenuLogo';
import './DesktopMenu.scss';

const DesktopMenu = ({ bannerConfig, closeBanner }) => (
  <div className={'menu-desktop'}>
    <nav className={'menu-desktop__nav'}>
      {!!bannerConfig?.display && (
        <NewTopBanner config={bannerConfig} onClose={closeBanner} />
      )}
      <div className={'menu-desktop__nav-wrapper w-container'}>
        <MenuLogo />
        <ul className={'menu-desktop__nav-list'}>
          {NAVIGATION_ITEMS.map(item => (
            <DesktopMenuNavigationItem
              key={item.name}
              item={item}
              isBannerActive={!!bannerConfig?.display}
            />
          ))}
          <li>
            <CtaButton contactClasses={['menu-desktop__button']} />
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

DesktopMenu.defaultProps = {
  bannerConfig: {
    button: {
      color: 'white',
      label: {
        desktop: 'Sign up',
        mobile: 'Read',
      },
    },
    bg: '',
    display: false,
    description: {
      desktop: '',
      mobile: '',
    },
  },
  closeBanner: () => {},
};

DesktopMenu.propTypes = {
  bannerConfig: PropTypes.shape({
    button: buttonConfigShape,
    display: PropTypes.bool,
    bg: PropTypes.string,
    description: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
  closeBanner: PropTypes.func,
};

export default DesktopMenu;
