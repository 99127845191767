import React from 'react';
import Logo from 'assets/images/logo-menu.svg';
import LogoWhite from 'assets/images/brand/logo-white.svg';
import LogoBig from 'assets/images/boldare-logo-big.svg';
import PropTypes from 'prop-types';

// Extracted from nav without refactor
const MenuLogo = ({ isTop, isLogoBig, show, color }) => (
  <div className={'navbar__logos '}>
    <a href={'/'} className={'navbar__logo-container'} id={'navbar-logo'}>
      <div className={'hide-desktop'}>
        <img
          src={show ? LogoWhite : Logo}
          className={'image navbar__logo--img'}
          alt={'Digital product design company Boldare'}
          loading={'lazy'}
        />
      </div>
      {(isTop && color[0] === 'white') || !show ? (
        <div className={'hide-mobile hide-tablet'}>
          <img
            src={LogoWhite}
            className={'image navbar__logo--img'}
            alt={'Digital product design company Boldare'}
            loading={'lazy'}
          />
        </div>
      ) : (
        <div className={'hide-mobile hide-tablet'}>
          <img
            src={isLogoBig ? LogoBig : Logo}
            className={'image navbar__logo--img'}
            alt={'Digital product design company Boldare'}
            loading={'lazy'}
          />
        </div>
      )}
    </a>
  </div>
);

MenuLogo.defaultProps = {
  isTop: false,
  isLogoBig: false,
  color: ['', ''],
  show: true,
};

MenuLogo.propTypes = {
  isTop: PropTypes.bool,
  isLogoBig: PropTypes.bool,
  color: PropTypes.array,
  show: PropTypes.bool,
};

export default MenuLogo;
