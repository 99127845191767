import PropTypes from 'prop-types';
import React from 'react';
import { classNames } from 'utils';
import { Experiment, Variant } from '@marvelapp/react-ab-test';
import { ClientSideComponent } from 'components/ClientSideComponent';
import '../../NavBar.scss';

// Extracted from nav without refactor
// Component added for A/B Tests
// In case of passed Button prop to NavBar container displays it
const CtaButton = ({ button, location, contactClasses }) =>
  button ? (
    <a
      id={button.id}
      href={button.link}
      className={classNames(
        'button',
        'button--primary',
        'button--yellow',
        'button--cta',
        ...contactClasses,
        {
          'navbar__email--home': location.pathname === '/',
          [button.className]: button.className,
        },
      )}
    >
      {button.label}
    </a>
  ) : (
    <ClientSideComponent>
      <Experiment name={'navbar-cta-ab'}>
        <Variant name={'navbar-cta-a'}>
          <a
            id={'contact-navbar--button-a-write'}
            href={'/resources/app-costs-calculator/'}
            className={classNames(
              'button',
              'button--primary',
              'button--yellow',
              'button--cta',
              'ga-button-cta',
              ...contactClasses,
              {
                'navbar__email--home': location.pathname === '/',
              },
            )}
          >
            Get estimate
          </a>
        </Variant>
        <Variant name={'navbar-cta-b'}>
          <a
            id={'contact-navbar--button-b-contact'}
            href={'/resources/app-costs-calculator/'}
            className={classNames(
              'button',
              'button--primary',
              'button--yellow',
              'button--cta',
              'ga-button-cta',
              ...contactClasses,
              {
                'navbar__email--home': location.pathname === '/',
              },
            )}
          >
            Get estimate
          </a>
        </Variant>
      </Experiment>
    </ClientSideComponent>
  );

CtaButton.defaultProps = {
  button: undefined,
  contactClasses: [],
  location: {},
};

CtaButton.propTypes = {
  button: PropTypes.shape({
    link: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
  }),
  contactClasses: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.object,
};

export default CtaButton;
