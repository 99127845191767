import React from 'react';
import IconSVG from 'components/IconSVG';
import { MenuIcon } from 'components/MegaMenu/subcomponents';
import PropTypes from 'prop-types';
import './IconList.scss';

const IconList = ({ title, links }) => {
  if (!links) return null;
  return (
    <div className={'menu-icon-list'}>
      <h2 className={'menu-icon-list__title'}>{title}</h2>
      <ul className={'menu-icon-list__list'}>
        {links.map(link => (
          <li key={link.name} className={'menu-icon-list__item'}>
            <a href={link.url} className={'menu-icon-list__link'}>
              <div className={'menu-icon-list__icon'}>
                <MenuIcon type={link.icon} alt={`${title} icon`} />
              </div>
              <div>
                <span className={'menu-icon-list_title'}>{link.name}</span>
                {link.description && (
                  <span className={'menu-icon-list__description'}>
                    {link.description}
                  </span>
                )}
              </div>
              <div className={'menu-icon-list__arrow'}>
                <IconSVG name={'arrow-right'} color={'violet'} />
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

IconList.defaultProps = {
  title: '',
  links: [],
};

IconList.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
      image: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
};

export default IconList;
