import React from 'react';
import PropTypes from 'prop-types';
import CtaButton from 'components/NavBar/subcomponents/MenuCta';
import { classNames } from 'utils';
import { NAVIGATION_ITEMS } from '../../helpers';
import MenuNavigationItem from '../MenuNavigationItem';
import './MobileMenuList.scss';

const MobileMenuList = ({ isOpen }) => (
  <ul
    className={classNames('mobile-menu-list', {
      'mobile-menu-list--active': isOpen,
    })}
  >
    {NAVIGATION_ITEMS.map(item => (
      <MenuNavigationItem key={item.name} item={item} />
    ))}
    <li>
      <CtaButton contactClasses={('navbar__email', 'contact-mail')} />
    </li>
  </ul>
);

MobileMenuList.defaultProps = {
  isOpen: false,
};

MobileMenuList.propTypes = {
  isOpen: PropTypes.bool,
};

export default MobileMenuList;
