import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { AnnouncementBanner, Link, Button } from '..';
import { useIsMobile } from '../../hooks/useIsMobile';
import './TopBanner.scss';

export const buttonConfigShape = PropTypes.shape({
  color: PropTypes.string,
  label: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  url: PropTypes.string,
});

const BannerButton = ({ config: { url, color, label }, isMobile }) => (
  <Link
    id={`top-banner-webinar`}
    additionalClass={'top-banner-join__button newsletter-subscribe'}
    to={url}
  >
    <Button
      color={color}
      id={'banner-top-cta-button'}
      ariaName={'Subscribe button'}
    >
      {isMobile ? label.mobile : label.desktop}
    </Button>
  </Link>
);

BannerButton.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  config: buttonConfigShape.isRequired,
};

const TopBanner = ({ onClose, config }) => {
  const isMobile = useIsMobile(480);
  const device = isMobile ? 'mobile' : 'desktop';
  const { button, description, display } = config;
  const text = description[device];

  return (
    display && (
      <div className={classNames('top-banner-wrapper')}>
        <AnnouncementBanner
          additionalClass={classNames('top-banner')}
          onClose={onClose}
          style={{ '--announcement-banner-bg': config.bg }}
        >
          <div className={'top-banner__description'}>
            <div
              className={'top-banner__description'}
              style={{
                '--top-banner-font-color': description.color,
              }}
            >
              {text}
            </div>
          </div>
          <div className={'top-banner-join'}>
            {button.url && (
              <BannerButton config={button} isMobile={isMobile} />
            )}
          </div>
        </AnnouncementBanner>
      </div>
    )
  );
};

TopBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
  config: PropTypes.shape({
    button: buttonConfigShape,
    display: PropTypes.bool,
    bg: PropTypes.string,
    description: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
};

TopBanner.defaultProps = {
  config: {
    button: {
      color: 'white',
      label: {
        desktop: 'Sign up',
        mobile: 'Read',
      },
    },
    bg: '',
    display: false,
    description: {
      desktop: '',
      mobile: '',
    },
  },
};

export default TopBanner;
