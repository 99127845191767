import React from 'react';
import PropTypes from 'prop-types';
import './FeaturedList.scss';

const FeaturedList = ({ title, links }) => {
  if (!links) return null;
  return (
    <div className={'featured-list'}>
      <h2 className={'featured-list__title'}>{title}</h2>
      <ul className={'featured-list__list'}>
        {links.map(({ name, description, url, image, alt }) => {
          return (
            <li key={name} className={'featured-list__item'}>
              <a href={url} className={'featured-list__item-link'}>
                {image && alt && (
                  <div className={'featured-list__item__image-wrapper'}>
                    <img
                      src={image}
                      alt={alt}
                      className={'featured-list__item__image'}
                    />
                  </div>
                )}
                <span className={'featured-list__item__title'}>{name}</span>
                {description && (
                  <span className={'featured-list__item__description'}>
                    {description}
                  </span>
                )}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FeaturedList.defaultProps = {
  title: '',
  links: [],
};

FeaturedList.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
      image: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
};

export default FeaturedList;
