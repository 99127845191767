const SERVICES = {
  name: 'Services',
  categories: [
    {
      type: 'ICON_LIST',
      name: 'DIGITAL TRANSFORMATION SERVICES:',
      links: [
        {
          name: 'Software development',
          description:
            'Web development, Mobile Development, No Code Development, Custom Application Development',
          icon: 'development',
          url: '/services/software-development-outsourcing/',
        },
        {
          name: 'Digital Design',
          description:
            'Product Design, UI/UX Design, Design Systems Digital Prototyping',
          icon: 'business',
          url: '/ux-ui-design-consulting-services/',
        },
        {
          name: 'Generative AI',
          description:
            'AI Prototyping and MVP Development, AI Software Development and Consulting, AI Integration and Deployment',
          icon: 'magnifier',
          url: '/services/ai-software-development-consulting/',
        },
        {
          name: 'Product Innovation & Strategy',
          description:
            'User Research & Testing, Product Discovery, Product Strategy & Roadmap, AI Strategy and Roadmap',
          icon: 'design',
          url: '/blog/how-do-you-make-first-product-discovery-workshops/',
        },
        {
          name: 'Quality & Project Management',
          description:
            'Quality Assurance, Agile Project Management, Product Management',
          icon: 'prototype',
          url: '/services/agile-coaching-services/',
        },
        {
          name: 'DevOps & Infrastructure',
          description:
            'Cloud Services, Continuous Integration and Continuous Deployment, Infrastructure Automation, Containerization',
          icon: 'mvp',
          url: '/services/devops-consulting-services/',
        },
        {
          name: 'Consulting & Scaling',
          description: 'Agile Coaching, Agile Teams Scaling, CTO as a Service',
          icon: 'pmf',
          url:
            '/work/cto-as-a-service-solves-the-problems-of-a-us-digital-product-company/',
        },
        {
          name: 'Testing & Quality',
          description:
            'Automated Testing, Code Audit and UX Audit, Test Strategy and Planning',
          icon: 'scaling',
          url: '/blog/ensuring-software-quality-boldare-qa-services/',
        },
      ],
    },
    {
      name: 'DEVELOPMENT CAPACITY SCALING:',
      type: 'SIMPLE_LIST',
      links: [
        {
          name: 'Dedicated Teams',
          url: '/services/development-teams/',
        },
        {
          name: 'Team Augmentation',
          url: '/services/team-augmentation/',
        },
        {
          name: 'Product Creation',
          url: '/services/product-design-and-development/',
        },
      ],
    },
  ],
};

const KNOWLEDGE_HUB = {
  name: 'Knowledge hub',
  categories: [
    {
      name: 'FEATURED BLOG POSTS:',
      type: 'FEATURED_LIST',
      links: [
        {
          name: 'Headless CMS: A Guide for CTOs and Product Owners',
          description:
            'When creating digital products that require a CMS, you might wonder which solution to choose...',
          image:
            'https://res.cloudinary.com/de4rvmslk/image/upload/w_300/f_auto,q_auto//img/headlessCMS.png',
          alt:
            'Planes in space with floating laptop and human hand holding a phone',
          url: '/blog/headless-cms-guide-for-ctos/',
        },
        {
          name:
            'How to Build Applications You Won’t Have to Rewrite: A MACH Perspective',
          description:
            'In software development, creating systems that can grow and adapt over time is essential. At Boldare, we...',
          image:
            'https://res.cloudinary.com/de4rvmslk/image/upload/w_300/f_auto,q_auto//MACH.png',
          alt: 'Two people preparing airplane for flight',
          url: '/blog/mach-framework/',
        },
      ],
    },
    {
      name: 'RESOURCES:',
      type: 'SIMPLE_LIST',
      links: [
        {
          name: 'Blog',
          url: '/blog/',
        },
        {
          name: 'Ebooks',
          url: '/resources/',
        },
        {
          name: 'Videos',
          url: 'https://www.youtube.com/channel/UCOTEkbD0MSAmBzGFNrnRaxQ',
        },
        {
          name: 'Podcasts',
          url:
            'https://open.spotify.com/show/3VpRL4e89G681woPahmC1L?si=5036bd5f10ea4642',
        },
        {
          name: 'Boldare boards',
          url: 'https://boards.boldare.com/',
        },
      ],
    },
    {
      name: 'KNOWLEDGE HUB:',
      type: 'SIMPLE_LIST',
      links: [
        {
          name: 'App cost calculator',
          url: '/resources/app-costs-calculator/',
        },
        {
          name: 'Newsletter',
          url: '/apb-community-newsletter/',
        },
        {
          name: 'Agile Product Builders',
          url: 'https://form.typeform.com/to/PaMq1c0N/',
        },
      ],
    },
    {
      name: 'All Resources',
      type: 'BUTTON_LINK',
      url: '/resources/',
    },
  ],
};

export const NAVIGATION_ITEMS = [
  { ...SERVICES },
  {
    name: 'Case studies',
    url: '/work/',
  },
  {
    name: 'Clients',
    url: '/clients/',
  },
  {
    name: 'About us',
    url: '/about/',
  },
  { ...KNOWLEDGE_HUB },
  {
    name: 'Career',
    url: '/career/',
  },
];
