import React from 'react';
import { useInView } from 'react-intersection-observer';

import QuoteMark from 'assets/images/home/hero/quote.svg';

import { HeroSlider, Link } from 'components';
import { opinions } from 'data/clientsOptions';

import { Dictionary } from './subcomponents';

import './Hero.scss';
import PropTypes from 'prop-types';
import { defaultOptions, optionsShape} from 'components/HeroSlider/utils';

// SEO non-JS hack
const HeroNoScript = ({ nsOpinions }) => {
  return (
    <noscript>
      <div className={'hero-caption__cite'}>
        <ul>
          {nsOpinions.map(item => (
            <li>
              <blockquote>
                {item.text.firstText}{' '}{item.text.underlineText}{' '}{item.text.secondText}
              </blockquote>
              {item.client.name}{' '}{item.client.surname}{' '}({item.client.company})
              <Link to={item.url ?? '/work/'}>
                <button
                  type={'button'}
                  className={
                    'button button--primary button--white contact-button'
                  }
                >
                  {item.url
                    ? `See why ${item.client.name} recommends us`
                    : 'Explore case studies'}
                </button>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </noscript>
  );
};
HeroNoScript.defaultProps = {
  nsOpinions: defaultOptions,
};
HeroNoScript.propTypes = {
  nsOpinions: PropTypes.shape(optionsShape),
};

const Hero = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <section className={'hero w-container'} ref={ref} id={'hero'}>
      <div className={'hero__container'}>
        <img
          src={QuoteMark}
          alt={'quotation mark'}
          className={'hero__image'}
        />
        <HeroSlider inView={inView} key={inView} slides={opinions} />
      </div>
      <Dictionary />
      <HeroNoScript nsOpinions={opinions} />
    </section>
  );
};

export default Hero;
