import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NewTopBanner } from 'components';
import { buttonConfigShape } from 'components/TopBanner/new';
import MobileMenuList from './MobileMenuList';
import MenuLogo from '../../MenuLogo';
import MenuTrigger from './MenuTrigger';
import './MobileMenu.scss';

const MobileMenu = ({ bannerConfig, closeBanner }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={'mobile-menu'}>
      <nav className={'mobile-menu__nav'}>
        <div className={'mobile-menu__nav-wrapper'}>
          <MenuLogo show={false} />
          <MenuTrigger isOpen={isOpen} toggle={() => setIsOpen(p => !p)} />
        </div>
        <MobileMenuList isOpen={isOpen} />
      </nav>
      {!!bannerConfig?.display && (
        <NewTopBanner config={bannerConfig} onClose={closeBanner} />
      )}
    </div>
  );
};

MobileMenu.defaultProps = {
  bannerConfig: {
    button: {
      color: 'white',
      label: {
        desktop: 'Sign up',
        mobile: 'Read',
      },
    },
    bg: '',
    display: false,
    description: {
      desktop: '',
      mobile: '',
    },
  },
  closeBanner: () => {},
};

MobileMenu.propTypes = {
  bannerConfig: PropTypes.shape({
    button: buttonConfigShape,
    display: PropTypes.bool,
    bg: PropTypes.string,
    description: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
  closeBanner: PropTypes.func,
};

export default MobileMenu;
