import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SingleArticle from 'views/singleArticle';
import Layout from 'components/layout';
import { NavBar, PopupRedirect } from 'components';
import { pages } from 'config/pages';

const ArticlePage = ({ data, pageContext }) => {
  const {
    markdownRemark: postData,
    allMarkdownRemark,
    categories,
    localSearchPages,
  } = data;
  const page = pages.article;
  const { navColors } = page;

  const author = allMarkdownRemark.edges.find(
    edge =>
      edge.node.frontmatter.templateKey === 'author-page' &&
      edge.node.frontmatter.title === postData.frontmatter.author,
  ).node.frontmatter;

  const authorAdditional =
    postData.frontmatter.authorAdditional &&
    allMarkdownRemark.edges.find(
      edge =>
        edge.node.frontmatter.templateKey === 'author-page' &&
        edge.node.frontmatter.title === postData.frontmatter.authorAdditional,
    ).node.frontmatter;

  const articleList = allMarkdownRemark.edges.filter(
    edge =>
      (edge.node.frontmatter.templateKey === 'article-page' ||
        edge.node.frontmatter.templateKey === 'youtube-video' ||
        edge.node.frontmatter.templateKey === 'case-study-page') &&
      edge.node.frontmatter.settings.category !== 'Case Study',
  );

  return (
    <Layout page={page} pageContext={pageContext}>
      <NavBar color={navColors} />
      <PopupRedirect message={'article'} />
      <SingleArticle
        post={postData}
        postList={articleList}
        author={author}
        authorAdditional={authorAdditional}
        allArticles={allMarkdownRemark}
        allBlogCategories={categories}
        localSearchPages={localSearchPages}
        canonicalPath={pageContext.canonicalPath}
      />
    </Layout>
  );
};

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    allMarkdownRemark: PropTypes.object,
    categories: PropTypes.object,
    localSearchPages: PropTypes.shape({
      index: PropTypes.string,
      store: PropTypes.object,
    }),
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default ArticlePage;

export const blogPostMarkdownRemarkFragment = graphql`
  fragment BlogPostMarkdownRemarkFragment on MarkdownRemark {
    id
    html
    fields {
      slug
    }
    frontmatter {
      title
      specialArticle
      cover
      coverComponent
      tags
      lead
      content {
        body
        fullwidthImage {
          image
          alt
          comment
        }
        theme
        entryComponent
      }
      settings {
        bannerHidden
        campaign
        category
        canonical
        date
        metaDescription
        metaTitle
        related {
          title
        }
        slugType
        slug
        blogCategories
        downloadPardotLink
        downloadPdfLink
      }
      author
      authorAdditional
      mainContent {
        related {
          title
        }
      }
    }
  }
`;

export const blogPostAllMarkdownRemarkFragment = graphql`
  fragment BlogPostAllMarkdownRemarkFragment on MarkdownRemarkConnection {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          job
          photo
          slug
          cover
          lead
          templateKey
          specialArticle
          isExternalAuthor
          settings {
            date
            slug
            type
            category
          }
          author
          authorAdditional
          box {
            content {
              title
              tileDescription
              tags
              coverImage
            }
            settings {
              tileColor
              textColor
              link
            }
            type
          }
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    localSearchPages: localSearchPages {
      index
      store
    }
    markdownRemark(id: { eq: $id }) {
      ...BlogPostMarkdownRemarkFragment
    }
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___settings___date] }
    ) {
      ...BlogPostAllMarkdownRemarkFragment
      edges {
        node {
          frontmatter {
            settings {
              slugType
            }
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { in: ["category-page"] }
          isBlogCategory: { eq: true }
        }
      }
    ) {
      ...markdownFields
    }
    authors: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { in: ["author-page"] } } }
    ) {
      ...markdownFields
    }
  }
`;
