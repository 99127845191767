import React from 'react';
import { classNames } from 'utils';
import PropTypes from 'prop-types';
import './MenuTouchable.scss';

const MenuTouchable = ({ isFocused, color, url, onClick, children }) => {
  if (url) return <a href={url}>{children}</a>;
  return (
    <button
      onClick={onClick}
      className={classNames('menu-touchable', `menu-touchable--${color}`)}
    >
      {children}
      <span
        className={classNames(
          'menu-touchable__icon',
          `menu-touchable__icon--${color}`,
          {
            'menu-touchable__icon--rotate': isFocused,
          },
        )}
      />
    </button>
  );
};

MenuTouchable.defaultProps = {
  isFocused: false,
  color: 'black',
  url: '',
  onClick: () => {},
  children: null,
};

MenuTouchable.propTypes = {
  isFocused: PropTypes.bool,
  color: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default MenuTouchable;
