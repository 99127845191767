import { Location } from '@reach/router';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { castArray, kebabCase } from 'lodash';

import { NewTopBanner } from 'components';
import { AnnouncementBannerSpace } from 'components/AnnouncementBanner';
import { classNames } from 'utils';
import MegaMenu from 'components/MegaMenu';
import { CountryContactPhoneNumbers } from 'config/constants/phoneNumbers';
import config from '../../../meta/config';

import './NavBar.scss';
import Menu from './subcomponents/Menu';
import CtaButton from './subcomponents/MenuCta';
import MenuLogo from './subcomponents/MenuLogo';

const { topBars } = config;

export const STICKY_BANNER_COOKIE = 'sticky-banner-webinar';

const NavBar = ({
  cookieBar,
  additionalClass,
  isLogoBig,
  button,
  phoneNumber,
  color,
  show,
  style,
  bannerHidden,
  paths,
  isContactAdded,
}) => {
  const [isTop, setTop] = useState(true);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [megaMenuHover, setMegaMenuHover] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [showSubpages, setShowSubpages] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [bannerContent, setBannerContent] = useState({});

  // when mega menu or button is hovered, show mega menu
  if (megaMenuHover) {
    setShowMegaMenu(true);
  } else {
    setTimeout(() => {
      // debounce delay to handle fast moves between mega menu and button
      if (!megaMenuHover) setShowMegaMenu(false);
    }, 50);
  }

  const textColorClass = color[0]
    ? `navbar--text-${color[0]}`
    : 'navbar--text-black';
  const itemHover = color[2]
    ? `navbar--hover-${color[2]}`
    : 'navbar--hover-violet';
  const isActive = menuActive ? 'is-active' : '';
  const isBannerActive = Boolean(bannerContent?.display) && !bannerHidden;
  const contactClasses = isContactAdded
    ? ['navbar__email', 'contact-mail']
    : [];

  const handleScroll = () => {
    const scrollOffset = 100;
    if (show) {
      setTop(window.scrollY < scrollOffset);
    }
    if (!show) {
      setTop(window.scrollY === 0 || window.scrollY > scrollPosition);
      setScrollPosition(window.scrollY);
    }
  };

  const closeMenu = () => {
    setMenuActive(false);
    setShowMegaMenu(false);
    if (typeof document !== 'undefined') {
      document.body.classList.remove('block-scroll');
    }
  };

  const closeMegaMenu = () => {
    setShowMegaMenu(false);
    setMegaMenuHover(false);
  };

  const toggleScroll = () => {
    if (typeof document !== 'undefined' && !menuActive) {
      document.body.classList.add('block-scroll');
    } else if (typeof document !== 'undefined') {
      document.body.classList.remove('block-scroll');
    }
  };

  const toggleMenu = () => {
    setMenuActive(prevState => !prevState);
    setShowMegaMenu(false);
    toggleScroll();
  };

  const toggleMegaMenu = () => {
    setShowMegaMenu(prevState => !prevState);
    setMegaMenuHover(prevState => !prevState);
  };

  // From the CMS we get all enabled banners, then we filter out the ones that were closed by user
  // and then we randomly choose one of the available banners
  const getRandomBannerVersion = () => {
    const closedBannerNames = castArray(cookie.load(cookieBar));
    const availableBanners = topBars.filter(
      topBar => !closedBannerNames.includes(topBar.name),
    );

    return (
      availableBanners[Math.floor(Math.random() * availableBanners.length)] ??
      null
    );
  };

  const closeBanner = () => {
    cookie.save(
      cookieBar,
      [...castArray(cookie.load(cookieBar) ?? []), bannerContent.name],
      {
        path: '/',
        maxAge: 10 * 24 * 60 * 60, // 10 days to avoid keeping to long list of closed banners
      },
    );
    setBannerContent(null);
  };

  const renderNavItems = navItemsPaths =>
    navItemsPaths.map(item => {
      const { link, isAnchor, name, children, isDropdown, sectionId } = item;

      const scrollToSection = () => {
        const section = document.getElementById(sectionId);
        if (section) {
          const headerHeight = document.querySelector('.navbar').offsetHeight;
          const yOffset =
            section.getBoundingClientRect().top +
            window.pageYOffset -
            headerHeight;
          window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }
      };

      const { pathname } =
        typeof window !== 'undefined' ? window.location : { pathname: '' };
      const pathnameBase = `/${pathname.split('/')[1]}`;
      const isActiveItem =
        link === `${pathnameBase}/` ||
        (isAnchor &&
          (pathnameBase === '/blog' || pathnameBase === '/resources'));
      const childrenShown = showSubpages ? 'show-services' : 'hide-services';

      const renderChildren = () =>
        children.map(child => (
          <li className={'dropdown__item'} key={child.name}>
            <a
              className={'navigation-dropdown__link'}
              href={`${child.link}`}
              onClick={closeMenu}
              id={`top-navigation-${kebabCase(
                child.name.replace(/\s+/g, '-').toLowerCase(),
              )}`}
            >
              {child.name}
            </a>
          </li>
        ));

      return (
        <li
          key={name}
          style={{ cursor: 'pointer' }}
          className={classNames(
            'navbar__menu-item',
            {
              dropdown: isDropdown,
              active: isActiveItem,
            },
            childrenShown,
          )}
          onClick={scrollToSection}
        >
          <div className={'navbar__item-wrapper'}>
            {children ? (
              <>
                <button
                  type={'button'}
                  className={'navigation-bar__menu-dropdown'}
                  onClick={() => setShowSubpages(prevState => !prevState)}
                >
                  <span
                    className={classNames('hamburger', 'hamburger--squeeze', {
                      'is-active': showSubpages,
                    })}
                  >
                    <span className={'hamburger-inner'} />
                  </span>
                  <a
                    className={'navigation-bar__menu-link'}
                    id={`top-navigation-${name
                      .replace(/\s+/g, '-')
                      .toLowerCase()}`}
                    href={!isAnchor && link}
                    key={`navigation-item-${name}`}
                  >
                    {name}
                    {isDropdown && (
                      <span className={'caret hide-mobile hide-tablet'} />
                    )}
                  </a>
                </button>
              </>
            ) : (
              <a
                className={'navigation-bar__menu-link'}
                id={`top-navigation-${name
                  .replace(/\s+/g, '-')
                  .toLowerCase()}`}
                href={link}
                key={`navigation-item-${name}`}
                onClick={closeMenu}
              >
                {name}
                {isDropdown && (
                  <span className={'caret hide-mobile hide-tablet'} />
                )}
              </a>
            )}
          </div>
          {isDropdown && (
            <ul
              className={classNames(
                'navbar__dropdown-content',
                childrenShown,
                {
                  [`navbar__dropdown--${color[1]}`]: color[1],
                },
              )}
            >
              {children && renderChildren()}
            </ul>
          )}
        </li>
      );
    });

  const renderListElement = children => {
    const { pathname } =
      typeof window !== 'undefined' ? window.location : { pathname: '' };
    const pathnameBase = `/${pathname.split('/')[1]}`;
    const shouldRenderServicesItem = pathnameBase !== '/career'; // Exclude for career page

    return (
      <li
        className={classNames('navbar__menu-item', 'dropdown', {
          active: showMegaMenu,
        })}
      >
        {shouldRenderServicesItem && children}
      </li>
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setBannerContent(getRandomBannerVersion());

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!paths)
    return (
      <Menu
        bannerConfig={isBannerActive && bannerContent}
        closeBanner={closeBanner}
      />
    );

  return (
    <Location>
      {({ location }) => (
        <>
          {isBannerActive && <AnnouncementBannerSpace />}
          <div
            className={classNames(
              'navbar',
              additionalClass,
              textColorClass,
              itemHover,
              isActive,
              {
                'navbar--scrolled': !isTop,
                'navbar--hidden': !show,
              },
              style,
            )}
          >
            {isBannerActive && (
              <NewTopBanner config={bannerContent} onClose={closeBanner} />
            )}
            <header
              className={classNames('navbar__brand', {
                'w-container': !phoneNumber,
                'w-container--call': phoneNumber,
              })}
            >
              <MenuLogo
                isTop={isTop}
                isLogoBig={isLogoBig}
                show={show}
                color={color}
              />
              <nav
                id={'navMenu'}
                className={classNames('navbar__menu', isActive)}
                aria-label={'main navigation'}
              >
                <ul className={'navbar__menu-ul'}>
                  {renderListElement(
                    <div
                      onMouseEnter={() => {
                        setMegaMenuHover(true);
                      }}
                      onMouseLeave={() => {
                        setMegaMenuHover(false);
                      }}
                      className={
                        'navbar__item-wrapper navbar__menu-mega-menu-button'
                      }
                    >
                      <button
                        type={'button'}
                        className={'navigation-bar__menu-dropdown'}
                        onClick={toggleMegaMenu}
                      >
                        <span
                          className={classNames(
                            'hamburger',
                            'hamburger--squeeze',
                            {
                              'is-active': showMegaMenu,
                            },
                          )}
                        >
                          <span className={'hamburger-inner'} />
                        </span>
                        <div
                          className={'navigation-bar__menu-link'}
                          id={'top-navigation-services'}
                        >
                          Services
                          <span className={'caret hide-mobile hide-tablet'} />
                        </div>
                      </button>
                    </div>,
                  )}
                  {showMegaMenu && (
                    <MegaMenu
                      setMegaMenuHover={setMegaMenuHover}
                      closeMenu={closeMegaMenu}
                      isBannerActive={isBannerActive}
                    />
                  )}
                  <noscript>
                    <MegaMenu
                      setMegaMenuHover={setMegaMenuHover}
                      closeMenu={closeMegaMenu}
                      isBannerActive={isBannerActive}
                    />
                  </noscript>
                  {renderNavItems(paths)}
                </ul>
                {!phoneNumber ? (
                  <CtaButton
                    button={button}
                    location={location}
                    contactClasses={contactClasses}
                  />
                ) : (
                  <div className={'navbar__call-container'}>
                    <a
                      href={`tel:${CountryContactPhoneNumbers.USA.value}`}
                      className={'phoneNumber'}
                    >
                      call {CountryContactPhoneNumbers.USA.displayValue}
                    </a>
                  </div>
                )}
              </nav>
              <button
                className={classNames(
                  'hamburger',
                  'hamburger--collapse',
                  `${isActive}`,
                )}
                type={'button'}
                onClick={toggleMenu}
                aria-label={'Menu'}
              >
                <span className={'hamburger-box'}>
                  <span className={'hamburger-inner'} />
                </span>
              </button>
            </header>
          </div>
        </>
      )}
    </Location>
  );
};

NavBar.defaultProps = {
  additionalClass: '',
  isLogoBig: false,
  color: ['', ''],
  show: true,
  style: [''],
  banner: undefined,
  button: undefined,
  phoneNumber: false,
  isContactAdded: true,
  cookieBar: 'sticky-banner',
  bannerHidden: false,
  paths: null,
};

NavBar.propTypes = {
  additionalClass: PropTypes.string,
  isLogoBig: PropTypes.bool,
  color: PropTypes.array,
  show: PropTypes.bool,
  style: PropTypes.array,
  banner: PropTypes.shape({}),
  button: PropTypes.shape({
    link: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
  }),
  phoneNumber: PropTypes.bool,
  isContactAdded: PropTypes.bool,
  cookieBar: PropTypes.string,
  bannerHidden: PropTypes.bool,
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
      onlyFooter: PropTypes.bool,
    }),
  ),
};

export default NavBar;
