import AgnitioLogo from './logos/agnitio-logo.svg';
import AkuityLogo from './logos/akuity-logo.svg';
import AnourLogo from './logos/anour-logo.svg';
import BeyondUnityLogo from './logos/beyondunity-logo.svg';
import BlaBlaCarLogo from './logos/blablacar-logo.svg';
import BlinkLogo from './logos/blink-logo.svg';
import Blu5Logo from './logos/blu5labs-logo.svg';
import BmwLogo from './logos/bmw-logo.svg';
import BoschLogo from './logos/bosch-logo.svg';
import BrasswillowLogo from './logos/brasswillow-logo.svg';
import ChartipediaLogo from './logos/chartipedia-logo.svg';
import CorelLogo from './logos/corel-logo.svg';
import DahlerLogo from './logos/dahler-logo.svg';
import DtiqLogo from './logos/dtiq-logo.svg';
import ElephantTrunkLogo from './logos/elephantTrunk-logo.svg';
import ElmLogo from './logos/elm-logo.svg';
import EzycountLogo from './logos/ezycount-logo.svg';
import HolaspiritLogo from './logos/holaspirit-logo.svg';
import HolidaytaxisLogo from './logos/holidaytaxis-logo.svg';
import InterstatedataLogo from './logos/interstatedata-logo.svg';
import IonoviewLogo from './logos/ionoview-logo.svg';
import LamdenLogo from './logos/lamden-logo.svg';
import LeasewebLogo from './logos/leaseweb-logo.svg';
import MaxeonLogo from './logos/maxeon-logo.svg';
import NboxLogo from './logos/nbox-logo.svg';
import NetvicoLogo from './logos/netvico-logo.svg';
import NovolyzeLogo from './logos/novolyze-logo.svg';
import OliveTreeLogo from './logos/olivetree-logo.svg';
import ACNLogo from './logos/acn_logo.svg';
import PanoramLogo from './logos/panoram_logo.svg';
import CaidioLogo from './logos/caidio_logo.png';
import SystemLocoLogo from './logos/system_loco_logo.png';
import StriveeLogo from './logos/strivee_logo.svg';
import PolcoLogo from './logos/polco-logo.svg';
import PrismaLogo from './logos/prisma-logo.svg';
import PsoLogo from './logos/pso-logo.svg';
import ShowbizmodelsLogo from './logos/showbizmodels-logo.svg';
import SonnenLogo from './logos/sonnen-logo.svg';
import SpamonkeysLogo from './logos/spamonkeys-logo.svg';
import SuenaLogo from './logos/suena-logo.svg';
import TakamolLogo from './logos/takamol-logo.svg';
import TauronLogo from './logos/tauron-logo.svg';
import TeamAlertLogo from './logos/teamalert-logo.svg';
import TheworkroomLogo from './logos/theworkroom-logo.svg';
import TuiLogo from './logos/tui-logo.svg';
import UndpLogo from './logos/undp-logo.svg';
import VinersLogo from './logos/viners-logo.svg';
import VirginRadioLogo from './logos/virginradio-logo.svg';
import YoucatLogo from './logos/youcat-logo.svg';
import DecathlonLogo from '../../assets/images/design-that-tells-stories/decathlon_logo.svg';
import HumanitasLogo from './logos/humanitas-logo.svg';
import MaxioLogo from './logos/maxio_logo.png';
import PlanetEscapeLogo from './logos/planet_escape_logo.png'
import ElfLogo from './logos/elf_logo.png'
import ResidentPropertyLogo from './logos/resident_property_logo.png'

export const CLIENTS = [
  {
    name: 'Decathlon',
    photo: DecathlonLogo,
    description:
      'Decathlon, a renowned French multinational corporation established in 1976, specializes in the design, manufacturing, and retail of sports goods. We contributed to the product development of one of their digital products, aimed at enhancing online sales numbers.',
  },
  {
    name: 'BlaBlaCar',
    photo: BlaBlaCarLogo,
    description:
      'BlaBlaCar is the world’s leading community-based travel app. As their strategic digital partner, we were part of the team working with the mission to be the go-to marketplace for shared transportation.',
  },
  {
    name: 'UNDP',
    photo: UndpLogo,
    description:
      "UNDP is a United Nations’ agency, operating in 170 nations fighting poverty & inequality. UNDP partnered with us to build a system for clear, efficient data representation, aiding their mission's analysis capabilities.",
  },
  {
    name: 'BOSCH',
    photo: BoschLogo,
    description:
      'Our long-term partnership with BOSCH focuses on a strategic digital project. We develop and maintain their warranty system for the BOSCH power tools that help build our homes, schools and hospitals.',
  },
  {
    name: 'TUI',
    photo: TuiLogo,
    description:
      'Our strategic partnership with TUI Musement, a global leader in local tours operating in 80 countries worldwide, was aimed at entering a new market segment and enriching travel experiences.',
  },
  {
    name: 'Corel',
    photo: CorelLogo,
    description:
      'Corel’s graphic processing solutions are used by millions around the world daily. Our partnership with the Canadian software giant focused on the development and rapid growth of their core products.',
  },
  {
    name: 'Sonnen',
    photo: SonnenLogo,
    description:
      'We’re a strategic long-term partner to sonnen, a rockstar in the solar energy industry that makes tangible change in the world, taking us a step closer towards a clean, green energy-powered world.',
  },
  {
    name: 'Maxeon',
    photo: MaxeonLogo,
    description:
      "Maxeon, a global solar innovation leader with 1000+ patents, serves customers in over 100 countries. As their partner, we've consulted on and developed top-notch solutions for their customers.",
  },
  {
    name: 'Leaseweb',
    photo: LeasewebLogo,
    description:
      "Leaseweb is a leading infrastructure-as-a-service provider with data centers in Europe, United States and Asia. Since 2008, we've been supporting their tremendous growth working on more than dozen products",
  },
  {
    name: 'PRISMA',
    photo: PrismaLogo,
    description:
      "We're a long-term partner to PRISMA, a leading European gas trading platform with the mission to secure Europe’s energy supply and enable a green transition for a sustainable future.",
  },
  {
    name: 'Takamol',
    photo: TakamolLogo,
    description:
      "We're a strategic digital partner to Takamol Holding, a Saudi Arabian government company. Working in close partnership, we develop web products that drive true change in the Saudi labor market.",
  },
  {
    name: 'Elm',
    photo: ElmLogo,
    description:
      'We’re strategic digital partner to Elm, the leading provider of innovative secure services & solutions in Saudi Arabia. Together we build solutions for both public and private sectors.',
  },
  {
    name: 'ACN',
    photo: ACNLogo,
    description:
      'ACN, founded in 1947 as a Catholic aid organization, is dedicated to serving persecuted Christians globally through information, prayer, and action, supporting thousands of projects annually through private donations. Boldare assisted them by designing their website.',
  },
  {
    name: 'Panoram',
    photo: PanoramLogo,
    description:
      "Panoram accelerates the legal industry's digital transformation by enhancing productivity, reducing costs, and risk through strategic planning, technology engineering, and managed services. Boldare provides DevOps support and team augmentation services to enhance their operational capabilities.",
  },
  {
    name: 'Caidio',
    photo: CaidioLogo,
    description:
      "Caidio develops data-driven technologies to enhance the quality and productivity of global concrete construction, addressing sustainability challenges. Boldare played a pivotal role by rewriting software to facilitate data collection from Caidio's hardware solution.",
  },
  {
    name: 'System Loco',
    photo: SystemLocoLogo,
    description:
      'System Loco excels in providing high-performance sensing and location capabilities, low-power functionality, and cost-effectiveness for IoT systems supporting applications. Boldare is implementing new front-end designs to improve user experience, efficiency and scalability of an application.',
  },
  {
    name: 'Strivee',
    photo: StriveeLogo,
    description:
      'Strivee, the leader in the French market, offers software designed for coaches and gym owners to effectively manage training, track athlete progress, and drive business development. Boldare assisted in optimizing the chat feature for their mobile app and its front end to improve user experience.',
  },
  {
    name: 'POLCO',
    photo: PolcoLogo,
    description:
      'POLCO is politics without the noise: an app for real-time citizen polling and validated analytics. They are responsible for creating democracy and letting people take part in political life.',
  },
  {
    name: 'Dahler',
    photo: DahlerLogo,
    description:
      'Dahler is a luxury real estate franchise company that makes it easy to manage properties. Our expertise in software development, coupled with their knowledge of the industry resulted in new growth.',
  },
  {
    name: 'Tauron Polska Energia',
    photo: TauronLogo,
    description:
      "We're a strategic digital partner to Tauron, the second-largest energy company in Poland that provides power to half of the country. We’re focused on integrating their vast IT architecture.",
  },
  {
    name: 'Suena',
    photo: SuenaLogo,
    description:
      'Suena is a powerful German startup revolutionizing energy storage & integrating power trading fueled by AI. We partnered to create their MVP for an expo which helped them raise 1.2 million euros.',
  },
  {
    name: 'HolidayTaxis',
    photo: HolidaytaxisLogo,
    description:
      "As HolidayTaxis' digital partner, we supported their rapid growth with our technology expertise. An agile approach to digital product development helped streamline their global airport-hotel transfers.",
  },
  {
    name: 'Lamden',
    photo: LamdenLogo,
    description:
      'For Lamden, a pioneering blockchain platform, we designed and developed a company website, enhancing their professional image in the cryptocurrency space.',
  },
  {
    name: 'EZYCount',
    photo: EzycountLogo,
    description:
      "We're a digital partner to EZYcount, a Switzerland-based accounting solution. We developed a web app for them with a mission to make life easier for Swiss businesses and the self-employed.",
  },
  {
    name: 'Novolyze',
    photo: NovolyzeLogo,
    description:
      'In partnership with Novolyze, a US food safety and quality innovator, we delivered full cycle product development, improving food safety and sustainability, helping to make everyday life healthier.',
  },
  {
    name: 'Akuity',
    photo: AkuityLogo,
    description:
      'Akuity is a cloud application deployment firm providing services and SaaS. In order to better showcase their services, they approached us to create a new website which positions them as a top player.',
  },
  {
    name: 'Agnitio',
    photo: AgnitioLogo,
    description:
      'Agnitio is a top-tier life sciences-focused software company. Partnering with the team at their Danish HQ, we developed innovative customer engagement solutions for pharma and medical companies.',
  },
  {
    name: 'Blink',
    photo: BlinkLogo,
    description:
      'Blink is the industry-leading digital platform for trading construction materials. Working in close partnership, we created an all-in-one platform to streamline supply chains.',
  },
  {
    name: 'Virgin Radio Oman',
    photo: VirginRadioLogo,
    description:
      "SABCO Media, Oman's leading media provider, partnered with us for Virgin Radio's digital debut in the region. As their partner, we designed and developed a website, live-streaming app, and mobile app.",
  },
  {
    name: 'Blu5 Labs',
    photo: Blu5Logo,
    description:
      'We partnered with Blu5 Labs, a telecom security leader, augmenting their teams. Our mission was to aid their in-house digital teams in minimizing attack surfaces while fortifying core operations.',
  },
  {
    name: 'ANOUR',
    photo: AnourLogo,
    description:
      'ANOUR, a renowned Copenhagen-based lamp manufacturer, partnered with us driven by a vision for expansion. Our work focuses on expanding their market share in the UK and Scandinavia.',
  },
  {
    name: 'DTIQ',
    photo: DtiqLogo,
    description:
      'DTiQ is a world-leading provider of intelligent surveillance and loss prevention. Boldare refined their product development, driving continuous innovation and enabling further growth.',
  },
  {
    name: 'Viners',
    photo: VinersLogo,
    description:
      'Viners is a United Kingdom brand and leading cutlers within the marketplace. We bridged the gap between their classic UK cutlery range and new online customers.',
  },
  {
    name: 'Youcat',
    photo: YoucatLogo,
    description:
      'YOUCAT Foundation is inspiring young Catholics by enabling them to learn about and share their faith and we support them in their transition to the digital age.',
  },
  {
    name: 'Netvico',
    photo: NetvicoLogo,
    description:
      'Netvico is a German company offering interactive digital signage solutions. Our long-term partnership targets their SaaS development software, helping their B2B customers manage marketing campaigns.',
  },
  {
    name: 'Polskie Składy Oponiarskie',
    photo: PsoLogo,
    description:
      'Polskie Składy Oponiarskie brings together the top Polish tire distributors. We helped drive their growth through the design and development of their products, making sure drivers stay safe.',
  },
  {
    name: 'Holaspirit',
    photo: HolaspiritLogo,
    description:
      'We are proud to work with Holaspirit, a company supporting self-organized teams. Our role involved creating their platform. Together we have empowered businesses to enhance the employee experience.',
  },
  {
    name: 'SpaMonkey',
    photo: SpamonkeysLogo,
    description:
      'SpaMonkey is a Hong Kong-based retailer for organic beauty products. As their strategic partner, we developed an e-commerce platform, improving their online presence and sales process.',
  },
  {
    name: 'TeamAlert',
    photo: TeamAlertLogo,
    description:
      'TeamAlert, a US innovator in emergency alert systems, partnered with us to improve their app. Their products protect the safety of real people around the globe and alert first responders.',
  },
  {
    name: 'Brass Willow',
    photo: BrasswillowLogo,
    description:
      'Our cooperation with an agile consulting company Brass Willow focused on streamlining and increasing their revenue. They are a company run by Scrum experts that took part in the creation of Scrum.org.',
  },
  {
    name: 'Showbiz Models Agency',
    photo: ShowbizmodelsLogo,
    description:
      'Showbiz Models Agency is a leading recruitment agency for models and hostesses in Italy. Working in close partnership, we created and developed new flagship software to replace a legacy app.',
  },
  {
    name: 'The Workroom',
    photo: TheworkroomLogo,
    description:
      'The Workroom, a London-based venture offering on-demand workspaces in luxury restaurants, developed with Boldare a mobile app to expand their reach in the coworking market providing comfortable spaces.',
  },
  {
    name: 'Interstate Data',
    photo: InterstatedataLogo,
    description:
      'Interstate Data, a mobile navigation provider, partnered with us to develop a revolutionary iOS app. Our journey involved the full cycle development of their vision, fostering a mobile change.',
  },
  {
    name: 'Chartipedia',
    photo: ChartipediaLogo,
    description: `We are proud to partner with Chartipedia to create a global chart community. We developed their MVP app, validating their vision and paving the way for a "Wiki for visual data".`,
  },
  {
    name: 'BMW Smorawiński',
    photo: BmwLogo,
    description:
      "Poland's largest car dealership, BMW Smorawiński provides a professional service center, a parts and accessories department, and more. Our partnership made car buying and maintenance more efficient.",
  },
  {
    name: 'BeyondUnity',
    photo: BeyondUnityLogo,
    description:
      "We are a strategic partner to Beyond Unity, an award-winning global consulting firm shaping leaders' impact. As their technology partner, we support them through innovative application development.",
  },
  {
    name: 'Iono View',
    photo: IonoviewLogo,
    description:
      'Iono View is the smart residential and commercial cleaning services solution in the MENA region, committed to supporting companies that keep living & working spaces clean and safe to be in.',
  },
  {
    name: 'Nbox',
    photo: NboxLogo,
    description:
      "Partnering with NBox, we're delivering innovative solutions that enhance their mail services, supporting their mission of providing convenient delivery, pickup, and virtual options.",
  },
  {
    name: 'Olive Tree Holdings',
    photo: OliveTreeLogo,
    description:
      'Olive Tree Ventures, an American business, partnered with us to build TrueRate Services. We developed an MVP, contributing to a 60% monthly traffic increase and over $100M in loan closures.',
  },
  {
    name: 'Elephant Trunk',
    photo: ElephantTrunkLogo,
    description:
      'The Elephant’s Trunk, an Irish publisher, partnered with us for custom, personalized book creation software. Their mission is to make inclusive stories that portray a diverse range of children.',
  },
  {
    name: 'Maxio',
    photo: MaxioLogo,
    description:
      'Maxio helps SaaS businesses uncover the financial insights they need to run an efficient SaaS business today. The client approached us with a need for external support for their own teams in the form of specialized experts proficient in 5 programming languages.',
  },
  {
    name: 'Planet Escape',
    photo: PlanetEscapeLogo,
    description:
      "Planet Escape, boutique travel agency specializes in roads less travelled, menus a la carte We assisted the client in defining new directions for the company's development by provixding research and strategy workshop, as well as delivering detailed website audit.",
  },
  {
    name: 'Humanitas',
    photo: HumanitasLogo,
    description:
      "The Humanitas Academy’s mission is to build a strong academic center in the region. We prepared a website project for the Academy, primarily aimed at selling services such as undergraduate and postgraduate studies, MBA programs, etc.",
  },
  {
    name: 'Elf',
    photo: ElfLogo,
    description:
      "e.l.f. Beauty, a globally recognized beauty company founded in 2004, is known for its innovative, prestige quality, cruelty-free products at an incredible value available in 15 countries. We are supporting the development of their eCommerce platform to enhance digital performance.",
  },
  {
    name: 'Resident Property',
    photo: ResidentPropertyLogo,
    description:
      "Resident Property, founded in 2014, is a UK-based leader in block management software, offering a cost-effective platform to simplify property management. It helps clients save time, money, and stay compliant. We support the evolution of their platform to enhance its digital capabilities.",
  },
];
