import React from 'react';
import IconSVG from 'components/IconSVG';
import PropTypes from 'prop-types';
import './SimpleList.scss';

const SimpleList = ({ title, links }) => {
  if (!links) return null;
  return (
    <div className={'simple-list'}>
      <h2 className={'simple-list__title'}>{title}</h2>
      <ul>
        {links.map(link => (
          <li key={link.name} className={'simple-list__element'}>
            <a href={link.url} className={'simple-list__link'}>
              <span className={'simple-list_text'}>{link.name}</span>
              <div className={'simple-list__icon'}>
                <IconSVG name={'arrow-right'} color={'violet'} />
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

SimpleList.defaultProps = {
  title: '',
  links: [],
};

SimpleList.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
      image: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
};

export default SimpleList;
