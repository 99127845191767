import React from 'react';
import PropTypes from 'prop-types';
import MenuCategory from '../MenuCategory';
import MenuTouchable from '../MenuTouchable';
import './MenuNavigationItem.scss';

const MenuNavigationItem = ({ item }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <li className={'menu-navigation-item'}>
      {isOpen && (
        <>
          <div
            className={
              'menu-navigation-item__spacer menu-navigation-item__spacer--top'
            }
          />
          <div
            className={
              'menu-navigation-item__spacer menu-navigation-item__spacer--middle'
            }
          />
          <div
            className={
              'menu-navigation-item__spacer menu-navigation-item__spacer--bottom'
            }
          />
        </>
      )}
      <MenuTouchable
        isFocused={isOpen}
        url={item.url}
        onClick={() => setIsOpen(p => !p)}
        color={isOpen ? 'violet' : 'black'}
      >
        <span className={'menu-navigation-item__text'}>{item.name}</span>
      </MenuTouchable>
      {item.categories && isOpen && (
        <ul className={'menu-navigation-item__list'}>
          {item.categories.map(category => {
            if (
              category.type !== 'ICON_LIST' &&
              category.type !== 'SIMPLE_LIST'
            ) {
              return null;
            }

            return <MenuCategory key={category.name} category={category} />;
          })}
        </ul>
      )}
    </li>
  );
};

MenuNavigationItem.defaultProps = {
  item: {},
};

MenuNavigationItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        links: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
            description: PropTypes.string,
            icon: PropTypes.string,
            image: PropTypes.string,
            alt: PropTypes.string,
          }),
        ),
        url: PropTypes.string,
      }),
    ),
  }),
};

export default MenuNavigationItem;
