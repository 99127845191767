import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Scroll from '../../Scroll';
import CircleImages from '../../CircleImages';
import '../SectionHello.scss';

const SectionHelloDesktop = ({ variant }) => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const firstTimeline = gsap.timeline();
    firstTimeline.to('.first', {
      display: 'none',
      opacity: 0,
      ease: 'linear',
    });
    const secondTimeline = gsap.timeline();
    secondTimeline
      .to('.show-next', {
        opacity: 1.5,
        ease: 'linear',
        display: 'block',
      })
      .to('.show-next', {
        opacity: 0,
        ease: 'linear',
        display: 'none',
      });
    const thirdTimeline = gsap.timeline();
    thirdTimeline.to('.show-next-next', {
      duration: 1.5,
      opacity: 1.5,
      ease: 'power2.out',
      display: 'block',
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.section-hello',
          start: 'top top',
          end: '+=300%',
          scrub: true,
        },
      })
      .add(firstTimeline)
      .add(secondTimeline)
      .add(thirdTimeline);
  }, []);

  if (variant === 'about-us-a') {
    return (
      <div className={'section-wrapper section-hello pinned-section'}>
        <section className={'grid-layout'}>
          <CircleImages />
          <h1 className={'about-subtitle first'}>
            Hello. We are Boldare - digital product creators & consultants
          </h1>
          <p className={'about-header first'}>
            We exist to help our clients{' '}
            <span className={'marker-highlight marker-highlight-a'}>
              redefine
            </span>{' '}
            their industries by building with them the products their users
            want
          </p>
          <Scroll className={'scroll-down first'} />
          <p className={'about-header about-header--hidden show-next'}>
            How do we win? We’ve created a pioneering{' '}
            <span className={'marker-highlight marker-black'}>
              environment
            </span>{' '}
            that jet-fuels the way we deliver our second-to-none services and
            products. Let us show you how it works!
          </p>
          <p className={'about-header about-header--hidden show-next-next'}>
            Created in 2004, we have{' '}
            <span className={'marker-highlight-small marker-black'}>
              grown
            </span>{' '}
            into a team of 90+ team with 4 offices across Europe.
          </p>
        </section>
      </div>
    );
  }

  return (
    <div className={'section-wrapper section-hello pinned-section'}>
      <section className={'grid-layout sticky-layout'}>
        <CircleImages />
        <h1 className={'about-subtitle first'}>We are Boldare</h1>
        <p className={'about-header first'}>
          How our services work is shaped by the{' '}
          <span className={'marker-highlight'}>environment</span> we have
          created
        </p>
        <p className={'about-text first'}>
          This is an invitation to explore that environment and see how it can
          impact your business.
        </p>
        <Scroll className={'scroll-down first'} />
        <p className={'about-header about-header--hidden show-next'}>
          Started in <span className={'marker-highlight-small'}>2004,</span>{' '}
          Boldare is now 200+ people working in 6 offices, including Hamburg,
          Amsterdam and Warsaw.
        </p>
        <p className={'about-header about-header--hidden show-next-next'}>
          Today we are the{' '}
          <span className={'marker-highlight marker-black'}>benchmark</span>{' '}
          others use. How?
        </p>
      </section>
    </div>
  );
};

export default SectionHelloDesktop;

SectionHelloDesktop.propTypes = {
  variant: PropTypes.string.isRequired,
};
