import React from 'react';
import OpenIcon from 'assets/images/menu-trigger/open.svg';
import ClosedIcon from 'assets/images/menu-trigger/closed.svg';
import PropTypes from 'prop-types';
import './MenuTrigger.scss';

const MenuTrigger = ({ isOpen, toggle }) => (
  <button onClick={toggle}>
    <img
      className={'menu-trigger'}
      src={isOpen ? OpenIcon : ClosedIcon}
      alt={'hamburger icon'}
    />
  </button>
);

MenuTrigger.defaultProps = {
  isOpen: false,
  toggle: () => {},
};

MenuTrigger.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default MenuTrigger;
