import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FeaturedList from '../FeaturedList';
import SimpleList from '../SimpleList';
import IconList from '../IconList';
import ButtonLink from '../ButtonLink';

const DesktopDropdown = ({ category }) => {
  const dropdown = useMemo(() => {
    switch (category.type) {
      case 'FEATURED_LIST':
        return <FeaturedList title={category.name} links={category.links} />;
      case 'SIMPLE_LIST':
        return <SimpleList title={category.name} links={category.links} />;
      case 'ICON_LIST':
        return <IconList title={category.name} links={category.links} />;
      case 'BUTTON_LINK':
        return <ButtonLink category={category} />;
      default:
        return null;
    }
  }, [category]);

  return <>{dropdown}</>;
};

DesktopDropdown.defaultProps = {
  category: {},
};

DesktopDropdown.propTypes = {
  category: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.string,
        image: PropTypes.string,
        alt: PropTypes.string,
      }),
    ),
    url: PropTypes.string,
  }),
};

export default DesktopDropdown;
