import React from 'react';
import { classNames } from 'utils';
import PropTypes from 'prop-types';
import MenuTouchable from '../MenuTouchable';
import MenuLink from '../MenuLink';
import './MenuCategory.scss';

const MenuCategory = ({ category }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  if (!category.links) {
    return null;
  }

  return (
    <li>
      <MenuTouchable
        isFocused={isOpen}
        onClick={() => setIsOpen(p => !p)}
        color={'disabled'}
      >
        <span className={'menu-category__text'}>{category.name}</span>
      </MenuTouchable>
      <ul
        className={classNames('menu-category__list', {
          'menu-category__list--active': isOpen,
          'menu-category__list--inactive': !isOpen,
        })}
      >
        {category.links.map(link => {
          return <MenuLink key={link.name} link={link} />;
        })}
      </ul>
    </li>
  );
};

MenuCategory.defaultProps = {
  category: {},
};

MenuCategory.propTypes = {
  category: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.string,
        image: PropTypes.string,
        alt: PropTypes.string,
      }),
    ),
    url: PropTypes.string,
  }),
};

export default MenuCategory;
