import BOX_TYPE from 'config/constants/boxTypes';
import logoBlaBlaCar from 'assets/images/logos/blablacar-color.svg';
import logoBldr from 'assets/images/logo-menu.svg';
import { Industry, Service, ProductStage, Region, Country } from './enums.js';

export const CASE_STUDIES = [
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.UnitedStates,
      clientName: 'DTIQ',
      coverImage: '/img/Boldare_and_DTIQ_case_study.jpg',
      title: "Case study: Optimizing DTIQ's Business Operations",
      description:
        "DTIQ, a US-based leader in advanced video surveillance and loss prevention solutions, approached Boldare with a significant challenge impacting their management and decision-making processes. In collaboration, Boldare developed a solution that not only streamlined DTIQ's internal operations but also benefited DTiQ’s customers across various industries. This case study explores how a custom solution can enhance business efficiency significantly.",
      tags: ['pmf', 'product development'],
      industry: [Industry.B2bServices],
      service: [Service.DevTeam],
      productStage: [ProductStage.PMF],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/work/case-study-optimizing-dtiq-business-operations/',
    },
    id: 'case-study-optimizing-dtiq-business-operations',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.Poland,
      clientName: 'Humanitas',
      coverImage: '/img/Humanitas_case_study.jpg',
      title:
        'From Outdated to Outstanding - Humanitas University Website Redesign for Better Business Results',
      description:
        "Humanitas University collaboration with Boldare underscores two critical factors for success in digital engagement: the pivotal role of design in connecting with the target persona and the benefits of consolidating services under one provider. Discover how we assisted one of Poland's largest private higher education institutions in tailoring their online presence to meet their business needs.",
      tags: ['pmf', 'product development'],
      industry: [Industry.Media],
      service: [Service.PDD],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/humanitas-university-website-redesign-case-study/',
    },
    id: 'humanitas-university-website-redesign-case-study',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.Finland,
      clientName: 'Caidio',
      coverImage:
        '/img/Cover_caidio_-_finish_startup_from_concrete_industry.png',
      title: 'Case Study: Caidio - Software Innovation in Concrete Industry',
      description:
        "Concrete, essential for modern urban construction, poses environmental challenges, consuming 10% of the world's industrial water and contributing to 8% of global CO2 emissions. Caidio, a Finnish startup, sought our assistance to implement their eco-friendly technology. This case study highlights our success in meeting their technical and business objectives within a tight budget, showcasing the potential for impactful improvements with limited resources.",
      tags: ['mvp', 'product development'],
      industry: [Industry.B2bServices],
      service: [Service.DevTeam],
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-caidio-concrete-tech-revolution/',
    },
    id: 'case-study-caidio-concrete-tech-revolution',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.SaudiArabia,
      clientName: '',
      coverImage: '/img/AI_POC_knowledge_base.jpg',
      title:
        'Demonstrating Viability with Proof of Concept: An AI-Powered Chatbot Case Study',
      description:
        'How can you make a real impact on your business with cutting-edge technology without spending a fortune? We recently assisted a client in understanding that they don’t need an R&D team to implement impactful innovations that save employee time. Their goal was clear but ambitious: simplify employees’ access to vast amounts of company data using AI. The goal was to develop a system that functioned like an exceptionally well-informed colleague, providing accurate information upon request in a hassle-free manner. Our proposed initial step was to create a Proof of Concept.',
      tags: ['artificial intelligence', 'prototype', 'product development'],
      industry: [Industry.B2bServices],
      service: [Service.AI],
      productStage: [ProductStage.Prototype],
      region: [Region.Asia],
    },
    settings: {
      link: '/work/ai-powered-chatbot-knowledge-base-case-study/',
    },
    id: 'ai-powered-chatbot-knowledge-base-case-study',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.Europe,
      clientName: '',
      coverImage:
        '/img/OpenAI_Assistant_API_based_assistant_for_a_consstruction_materials_platform.png',
      title:
        'Empowering Users of a Building Materials Trading Platform with a Smart, AI-Powered Assistant',
      description:
        'Discover how we supported a forward-thinking startup and created a state-of-the-art AI product for their clients. Keep reading to discover how adapting to change can drive your business’s progress and distinguish it from competitors.',
      tags: ['artificial intelligence', 'mvp', 'product development'],
      industry: [Industry.B2bServices],
      service: [Service.AI],
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-building-materials-ai-assistant/',
    },
    id: 'case-study-building-materials-ai-assistant',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.Germany,
      clientName: 'YOUCAT',
      coverImage:
        '/img/YOUCAT_daily_app_-_mobile_app_case_study_by_Boldare.png',
      title:
        'Case Study: user-centric, mobile development for the YOUCAT Foundation',
      description: `Creating an app is one thing. Establishing a bond with users and giving them a genuine reason to keep using the app is an entirely different and much more challenging matter.`,
      tags: ['e-commerce', 'pmf', 'product development'],
      industry: [Industry.Media],
      service: [Service.PDD],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-youcat-daily-app/',
    },
    id: 'case-study-cybersixgill-new-website',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.SaudiArabia,
      clientName: 'ZATCA',
      coverImage: '/img/ZATCA_API_case_stud_Boldare.png',
      title:
        'Case Study: Developing an e-invoice integration solution for ZATCA compliance in Saudi Arabia',
      description: `The Zakat, Tax, and Customs Authority (ZATCA) in Saudi Arabia has introduced major changes in how companies in the Kingdom handle their invoicing processes.`,
      tags: ['ZATCA', 'e-invoice', 'API'],
      industry: [Industry.ITServices],
      service: [Service.DevTeam],
      productStage: [ProductStage.MVP],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {
      link: '/work/zatca-e-invoice-integration-case-study/',
    },
    id:
      'case-study-implementing-an-in-house-e-invoice-integration-solution-for-zatca-compliance',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.Europe,
      clientName: '',
      coverImage: '/img/Bulk_shipping_industry_platform_case_study.png',
      title:
        'Bulk shipping industry: disrupting the market with a digital platform',
      description: `Discover how we partnered with our client to revolutionize the bulk shipping industry by developing a cutting-edge digital platform for buyers and sellers.`,
      tags: ['e-commerce', 'pmf', 'product development'],
      industry: [Industry.B2bServices],
      service: [Service.PDD],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-bulk-shipping-platform/',
    },
    id:
      'bulk-shipping-industry-startup-transforming-the-market-with-a-digital-platform',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.Ireland,
      clientName: '',
      coverImage: '/img/TeT_CaseStudy_cover.png',
      title: 'The Elephant’s Trunk - unusual e-commerce developed as an MVP',
      description: `Read on to find out about our approach to building an MVP of a book ordering platform.`,
      tags: ['e-commerce', 'children', 'mvp', 'rendering'],
      industry: [Industry.ECommerce],
      service: [Service.FCPD],
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/elephants-trunk-unusual-ecommerce-mvp/',
    },
    id: 'the-elephant-s-trunk-unusual-e-commerce-developed-as-an-mvp',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 1,
    content: {
      country: Country.Europe,
      clientName: '',
      coverImage: '/img/consulting_case_study.png',
      title:
        'Business solutions that helped our partner grow their business in post-pandemic conditions',
      description: `Find out what steps Boldare consultants took to help them resolve their issues and grow in the new competitive environment.`,
      tags: ['consulting', 'scaling', 'case study'],
      industry: [Industry.B2bServices],
      service: [Service.DigitalTransformation],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/business-consulting-case-study/',
    },
    id:
      'business-solutions-that-helped-our-partner-grow-their-business-in-post-pandemic-conditions',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 1,
    content: {
      country: Country.UnitedStates,
      clientName: '',
      coverImage: '/img/C4%20architecture%20case%20study.png',
      title:
        'C4 modeling at levels 1 and 2 - moving within a large domain despite documentation gaps',
      description: `Read about how we dealt with a C4 implementation for one of our US clients.`,
      tags: ['C4', 'modeling', 'product-market fit', 'case study'],
      industry: [Industry.ECommerce],
      service: [Service.PDD],
      productStage: [ProductStage.PMF],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/work/C4-modeling-using-levels-1-and-2-case-study/',
    },
    id:
      'c4-modeling-at-levels-1-and-2-how-to-move-around-a-large-domain-despite-gaps-in-documentation',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 1,
    content: {
      country: Country.Germany,
      clientName: '',
      coverImage: '/img/gas_capacity_platform_case.jpg',
      title:
        'Product-market fit and scaling for a large EU gas capacity management platform',
      description: `Find out how we supported the client in creating new modules and applying Agile processes for better team performance and higher product quality.`,
      tags: ['scaling', 'product-market fit', 'case study'],
      industry: [Industry.Energy],
      service: [Service.PDD],
      productStage: [ProductStage.PMF, ProductStage.Scaling],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/developing-gas-capacity-management-platform-case-study/',
    },
    id:
      'product-market-fit-and-scaling-for-a-large-eu-gas-capacity-management-platform',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 1,
    content: {
      country: Country.UnitedKingdom,
      clientName: '',
      coverImage: '/img/fintech_app_case_study.jpg',
      title:
        'Open bank-based, real-time payment solution for a UK fintech startup',
      description: `In this case study, we briefly describe our collaboration with a UK-based fintech startup and our role in implementing a real-time payments platform for the biggest bank in Latin America.`,
      tags: ['fintech', 'product-market fit', 'case study'],
      industry: [Industry.B2bServices],
      service: [Service.FCPD],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/fintech-case-study/',
    },
    id: 'open-bank-based-real-time-payment-solution-for-a-uk-fintech-startup',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 1,
    content: {
      country: Country.Europe,
      clientName: '',
      coverImage:
        '/img/Boldare_and_M2_collaboration_with_a_Tableau_based_MVP_app_for_media_company.png',
      title:
        'Saving resources for a media company with an MVP app and Tableau implementation',
      description: `With an MVP application and Tableau implementation, they’re saving approximately €70,000 monthly in terms of people capacity.`,
      tags: ['mvp', 'case study'],
      industry: [Industry.SAAS],
      service: [Service.FCPD],
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/tableau-based-mvp-for-media-company/',
    },
    id:
      'saving-resources-for-a-media-company-with-an-mvp-app-and-tableau-implementation',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 3,
    content: {
      country: Country.Netherlands,
      clientName: 'Leaseweb',
      coverImage:
        '/img/human-resource-challenge-in-product-development-industry.jpg',
      title:
        'Leaseweb: How we helped Leaseweb improve their customer experience',
      description: `Leaseweb is a leading Dutch cloud-based services provider. Their portfolio includes, among other things, customized server solutions. Read the case story of how Boldare built an automatic installation system for this client and made their server services more customer friendly.`,
      tags: ['server', 'CX'],
      industry: [Industry.ITServices],
      service: [Service.DigitalTransformation],
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/improving-customer-experience-leaseweb-case-study/',
    },
    id: 'how-we-helped-leaseweb-improve-their-customer-experience',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.UnitedStates,
      clientName: 'Team Alert',
      coverImage: '/img/TeamAlert-from-MVP-to-product-market-fit.jpg',
      title:
        'TeamAlert: transition from MVP to product-market fit results in 300% more users',
      description: `Through years of experience in building digital products, we’ve discovered that it’s not only important to lead the product through a full cycle of product development, but also to support the process of transition between individual phases. Here is an example of how we helped a US company - TeamAlert - with the transition of their digital app from the MVP stage to the product-market fit phase. They can now move forward with their global sales strategy. Read about our approach, steps taken, and client outcomes.`,
      tags: ['team alert', 'product-market fit'],
      industry: [Industry.B2bServices],
      service: [Service.FCPD],
      productStage: [ProductStage.PMF],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/work/teamalert-transition-from-mvp-to-pmf/',
    },
    id: 'how-we-helped-teamalert-transition-from-mvp-to-product-market-fit',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 2,
    content: {
      clientName: 'Team Alert',
      country: Country.UnitedStates,
      coverImage:
        '/img/Case_study_Team_Alert-_MVP_for_a_panic_button_company_from_the_US.jpg',
      title:
        'TeamAlert: functional app features bring new users and help with expansion to new markets',
      description: `Meet our recipe for emergency assistance: the “Team Alert” panic button. What improvements did we make to ensure it is well-developed and user-friendly? And how did we travel from MVP to PMF? Read the story.`,
      tags: ['team alert', 'product-market fit'],
      industry: [Industry.B2bServices],
      service: [Service.FCPD],
      productStage: [ProductStage.PMF],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/work/functionality-of-emergency-app/',
    },
    id:
      'how-to-find-the-golden-mean-between-simplicity-and-expanded-functionality-in-an-emergency-app',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 3,
    content: {
      country: Country.SaudiArabia,
      clientName: 'Takamol',
      coverImage:
        '/img/Case_study_of_Mowaamah_-_an_accessible_design_MVP_development_for_people_with_disabilities.jpg',
      title:
        'Mowaamah: making an app accessible and useful for people with disabilities',
      description: `Here’s the story of a unique app that we improved for one of our partners. The app is designed to help people with various disabilities and support them in everyday activities. Read on to find out what challenges we faced and what solutions brought us closer to success.`,
      tags: ['Takamol Holding', 'MVP'],
      industry: [Industry.HealthAndBeauty],
      service: [Service.FCPD],
      productStage: [ProductStage.MVP],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {
      link: '/work/building-accessible-app/',
    },
    id: 'how-to-build-an-accessible-app-for-people-with-disabilities?',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 3,
    content: {
      country: Country.UnitedStates,
      clientName: 'Olive Tree Ventures',
      coverImage:
        '/img/Commercial_Real_Estate_startup_case_study_True_Rate_Olive_Tree_Holding.jpg',
      title:
        'TrueRate: MVP development results in closing deals for $100M in 6 months',
      description: `Is it possible to build a prototype in one week? Is it possible to develop it into an MVP in just one month? Boldare’s development team proved it is. This case study is the story of a U.S. commercial real estate startup that got its wings from our specialists and is now ready to fly high, conquering a demanding market. Read the article and find out how we created the TrueRate Capital Markets Portal - a web-based marketplace for real estate owners and operators - and helped to close $100M in deals within half a year.`,
      tags: ['case study', 'commercial real estate', 'startup'],
      industry: [Industry.B2bServices],
      service: [Service.DigitalTransformation],
      productStage: [ProductStage.MVP],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/work/mvp-for-american-commercial-real-estate-startup-true-rate/',
    },
    id:
      'an-mvp-for-an-american-commercial-real-estate-startup-the-story-of-truerate',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.UnitedStates,
      clientName: '',
      coverImage: '/img/Case_study_CTOaaS.png',
      title:
        'E-commerce leader: CTO as a service helps to reach next milestone of growth',
      description: `Boldare provided CTO as a service (CTOaaS) for a leading US e-commerce company. The organization and Boldare partnered together to apply this alternative way of cooperating in the area of the chief technology officer’s competencies. This case study presents the process of identifying our client’s needs, investigating their critical problems, forming a CTOaaS team, and using the systemic loop to find the best solutions.`,
      tags: [
        'case study',
        'CTO as a service',
        'CaaS',
        'CTOaaS',
        'CTO services',
        Service.DigitalTransformation,
      ],
      industry: [Industry.ECommerce],
      service: [Service.CTOAsAService],
      productStage: [ProductStage.Scaling],
      region: [Region.NorthAmerica],
    },
    settings: {
      link:
        '/work/cto-as-a-service-solves-the-problems-of-a-us-digital-product-company/',
    },
    id: 'cto-as-a-service-solves-the-problems-of-a-us-digital-product-company',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.Germany,
      clientName: 'sonnen',
      coverImage:
        '/img/Case_study_Sonnen_-_digital_transformation_for_a_green_energy_service_provider.jpg',
      title: 'sonnen: digital transformation leading to higher efficiency',
      description: `Digital transformation is a complex process of reshaping an organization's services, products and culture. It’s not about digitalization for the sake of it. In most cases it means transforming a company's product portfolio, learning new perspectives and, as a result, improving the entire business. One of the most interesting digital transformation cases in our portfolio is our partnership with sonnen, a German energy sector company.`,
      tags: ['sonnen'],
      industry: [Industry.Energy],
      service: [Service.DigitalTransformation],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-sonnen-digital-transformation/',
    },
    id: 'case-study-sonnen-digital-transformation',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 5,
    content: {
      country: Country.Poland,
      clientName: 'Boldare Boards',
      coverImage: '/img/Boldare_Boards.png',
      title:
        'Boldare Boards: MVP that improved brand visibility and website traffic',
      description: `Back in 2019, we needed a tool that would help us to conduct
      various meetings online, mainly scrum retrospectives. It was supposed to
      be lightweight, versatile and easy to use. There are plenty of great
      applications on the market, but none of them matched our needs perfectly,
      so we decided to build a tool that would solve our problems. We are a
      product development and design company, after all!`,
      tags: ['chartipedia', 'awwwards'],
      industry: [Industry.SAAS],
      service: [Service.FCPD],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/boldare-boards-case-story/',
    },
    id: 'boldare-boards-case-study',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 2,
    content: {
      country: Country.HongKong,
      clientName: 'Chartipedia',
      coverImage:
        'img/Cases_study_-_Chartipedia_an_MVP_for_a_startup_based_on_Instagram.jpg',
      title:
        'Chartipedia: from Instagram to fully functional data platform in 8 weeks',
      description:
        'Data rules the world. It fuels our companies and supports our decision-making. To take advantage of data, there’s only one condition: you must understand it. And it’s hard to do if the only thing you see is a set of numbers, or boring pie charts made in Word 2007. So, how to change this? Chartipedia is the torch-bearer platform that connects designers who create interactive, data-backed visualizations, with those who seek information.',
      tags: ['chartipedia', 'awwwards'],
      industry: [Industry.B2bServices],
      service: [],
      productStage: [ProductStage.MVP],
      region: [Region.Asia],
    },
    settings: {
      link: '/work/case-study-chartipedia/',
    },
    id: 'chartipedia-article',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 3,
    content: {
      country: Country.UnitedKingdom,
      clientName: 'The Work Room',
      coverImage: 'img/workroom_cover.png',
      title: "The Work Room: MVP that revolutionized London's coworking scene",
      description: `Work out of the office? Yes, please! If you live in London, you are good to go. Every five days, a new co-working space opens in the city, which makes it a global growth leader. More than 4% of all office space in London is for co-working now. In the past 14 years, co-working has become a vibrant, profitable, and well-established industry that grows 15% yearly. And it's still open to innovation. The Work Room app is ready to provide it.`,
      tags: ['chartipedia', 'awwwards'],
      industry: [Industry.SAAS],
      service: [],
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/the-workroom-case-story/',
    },
    id: 'workroom-article',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 5,
    content: {
      country: Country.Poland,
      clientName: 'PlatformaOpon.pl (PSO)',
      coverImage:
        'img/Case_study_PSO_Polskie_Sklady_Oponiarskie_web_app_ecommerce.jpg',
      title: 'How we doubled the number of paying members using the app.',
      description:
        'The redevelopment of an advanced online B2B & B2C solution for tire wholesalers and workshops.',
      tags: ['PSO', 'e-commerce', 'B2B', 'Symfony'],
      industry: [Industry.ECommerce, Industry.B2bServices],
      service: [],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/polskie-sklady-oponiarskie-case-study/',
    },
    id: 'box-polskie-slady-oponiarskie-article',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 4,
    content: {
      country: Country.France,
      clientName: 'Holaspirit',
      coverImage: 'holaspirit_-_product_designs.png',
      title:
        'Helping a French startup to maintain their business while accelerating work on a new version of their platform.',
      description:
        'Holaspirit is a French company that created a platform to support organizations with implementation of self-managed methodologies, including holacracy. The idea to create such an app arose when owners of Talkspirit, the sister-company of holaspirit, were struggling with efficient implementation of such a system of self-organization their own company.',
      tags: ['holaspirit', 'holacracy'],
      industry: [Industry.ITServices, Industry.SAAS, Industry.B2bServices],
      service: [],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-holaspirit/',
    },
    id: 'box-holaspirit-article',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.France,
      coverImage:
        'img/Scaling_services_case_study_for_BlaBlaCar_european_unicorn.jpg',
      clientName: 'BlaBlaCar',
      clientLogo: logoBlaBlaCar,
      title:
        'Agile and skilled development teams for BlaBlaCar, a French unicorn',
      tags: ['php', 'UX/UI', 'scrum'],
      industry: [Industry.Travel],
      service: [Service.FCPD, Service.DevTeam, Service.PDD],
      subtitle: [''],
      description: `This is a story of Parisian unicorn BlaBlaCar and how they won a race for market domination in 27 countries, with help from Boldare’s development teams. Together we delivered 10 products for 27 countries over 18 months employing agile development and lean startup thinking`,
      productStage: [ProductStage.Scaling],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-story-blablacar/',
    },
    id: 'box-BlaBlaCar',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: true,
    priority: 1,
    content: {
      country: Country.Oman,
      coverImage:
        'img/MVP_in_4_weeks_-_case_study_of_Radio_Oman_radio_mobile_app.jpg',
      clientName: 'Virgin Radio Oman',
      clientLogo: 'logoVirgin',
      title: 'How to deliver and launch a mobile and web MVP in just 4 weeks.',
      tags: ['ReactJS', 'mobile', 'agile'],
      industry: [Industry.Media],
      service: [Service.FCPD, Service.DevTeam, Service.PDD],
      subtitle: [''],
      description: [
        'Sabco Media had a clear goal. They wanted to launch a web and a mobile app for the new radio station - Virgin Radio Oman. The trick? They had only 4 weeks to design, develop and test before launching their MVPs in the real world.',
      ],
      productStage: [ProductStage.MVP],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {
      link: '/work/case-study-virgin-radio-oman/',
    },
    id: 'box-virgin-radio-oman',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 5,
    content: {
      country: Country.HongKong,
      coverImage: 'img/case-study-pakt-web-app.jpg',
      clientName: 'Pakt',
      clientLogo: 'logoPakt',
      title: 'The Idea of Virtual Wardrobe',
      tags: ['JavaScript', 'CakePHP', 'UX/UI', 'HongKong'],
      industry: [Industry.ECommerce],
      service: [Service.DevTeam],
      subtitle: [''],
      description:
        'Pakt is an online, virtual wardrobe that allows us to catalog and store clothes in a safe place.',
      productStage: [ProductStage.Scaling],
      region: [Region.Asia],
    },
    settings: {
      link: '/work/case-study-pakt/',
    },
    id: 'box-pakt',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 3,
    content: {
      country: Country.UnitedStates,
      coverImage: 'img/work-oneida.jpg',
      clientName: 'Oneida',
      clientLogo: 'logoOneida',
      title: 'A short story about the partnership and values',
      tags: ['AngularJS', 'PHP', 'UX/UI', 'USA'],
      industry: [Industry.ECommerce],
      service: [Service.PDD],
      description:
        'See a case study of a virtual tabletop, that allows to mix and match Oneida dinnerware and cutlery ranges.',
      productStage: [ProductStage.MVP],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/work/case-study-oneida/',
    },
    id: 'box-oneida',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 5,
    content: {
      country: Country.HongKong,
      coverImage: 'img/work-spa-monkeys.jpg',
      clientName: 'SpaMonkeys',
      clientLogo: 'logoSpaMonkeys',
      title: 'Beauty Businesses in Hong Kong',
      tags: ['JavaScript', 'Angular', 'MVP', 'HongKong'],
      industry: [Industry.ECommerce, Industry.HealthAndBeauty],
      service: [Service.DevTeam],
      subtitle: [''],
      description:
        'The client turned to Boldare to build an online shop, locations catalogue and blog for the spa, beauty and wellness industry in Hong Kong. Our role included the creation and phased implementation of a series of comprehensive solutions.',
      productStage: [ProductStage.MVP],
      region: [Region.Asia],
    },
    settings: {
      link: '/work/case-study-spa-monkeys/',
    },
    id: 'box-spa-monkeys',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 4,
    content: {
      country: Country.Canada,
      coverImage: 'img/case-study-corel.png',
      clientName: 'Corel',
      clientLogo: 'logoCorel',
      title: 'Discover new ideas with Corel',
      tags: ['JavaScript', 'Angular', 'MVP', 'Canada'],
      industry: [Industry.SAAS],
      service: [Service.DevTeam],
      subtitle: [''],
      description:
        'See case study of how we created an online space for the knowledge exchange dedicated to vivid Corel community members.',
      productStage: [ProductStage.PMF],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/work/case-study-corel/',
    },
    id: 'box-corel',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 1,
    content: {
      country: Country.Germany,
      coverImage:
        'img/Case_study_-_MVP_for_sonnen_German_green_energy_service_provider.jpg',
      clientName: 'sonnen',
      clientLogo: 'logoSonnen',
      title:
        'How to create an app that connects users with the intangible world of solar energy.',
      tags: ['Python', 'MachineLearning', 'MVP', 'Germany'],
      industry: [Industry.Energy],
      service: [Service.MachineLearning],
      subtitle: [''],
      description: [
        '"Boldare’s company culture really fits us well, especially their startup atmosphere, their agility, and the engagement of the team members." Norbert Baumann, VP Research & Development / Digital',
      ],
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-sonnen/',
    },
    id: 'box-sonnen',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 3,
    content: {
      country: Country.UnitedStates,
      coverImage:
        'img/Boldare_case_study_-_Polco_a_voting_platform_for_American_local_goverments.jpg',
      clientName: 'Polco',
      clientLogo: 'logoPolco',
      title:
        'How Polco.us used the lean approach to deliver a voting platform.',
      tags: ['Information Architecture', 'UI', 'UX', 'HTML5', 'JavaScript'],
      industry: [Industry.SAAS],
      service: [Service.FCPD, Service.DevTeam, Service.PDD],
      subtitle: [''],
      description: [
        'A team of brilliant minds, made up of former soldiers from the US Air Force, molecular biologists, and Amazon economists, invited Boldare to build the app to energize citizens and gain valuable insights for policy makers.',
      ],
      productStage: [ProductStage.Prototype, ProductStage.MVP],
      region: [Region.NorthAmerica],
    },
    settings: {
      link: '/work/case-study-polco/',
    },
    id: 'box-Polco',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 5,
    content: {
      country: Country.Poland,
      coverImage: 'img/predictive-maintenance.jpg',
      clientName: 'Hack The Wind',
      clientLogo: 'logoHTW',
      title:
        'How to create predictive maintenance software using machine learning',
      tags: ['machinelearning', 'python', 'ux', 'lean'],
      industry: [Industry.Energy],
      service: [Service.FCPD, Service.DevTeam, Service.MachineLearning],
      subtitle: [''],
      description: [
        'The Boldare Machine Learning team delivered a web application for predictive maintenance of five wind turbine components in less than 48 hours. Using a variety of algorithms and their knowledge of application development, they created a web app that not only forecasts failures but also allows the user to take action and plan maintenance logistics.',
      ],
      productStage: [ProductStage.Prototype],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-predictive-maintenance/',
    },
    id: 'box-HTW',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 2,
    content: {
      country: Country.SaudiArabia,
      coverImage: 'img/Case_study_-_Tojar_MVP_ecommerce.jpg',
      clientName: 'Tojjar',
      clientLogo: 'logo910',
      title: 'How to deliver an e-commerce platform MVP in just 6 weeks.',
      tags: ['php', 'symfony', 'agile', 'mvp'],
      industry: [Industry.ECommerce],
      service: [Service.FCPD, Service.DevTeam, Service.PDD],
      subtitle: [''],
      description: [
        ' With investor funding at stake, Takamol had to deliver a fully functional MVP of their e-commerce platform within a tight deadline. They needed to find a partner who can consult, develop and solve problems quickly. Boldare team hopped on board and brought to life an online marketplace that has a potential to revolutionise economy in MENA region.',
      ],
      productStage: [ProductStage.MVP],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {
      link: '/work/case-study-tojjar/',
    },
    id: 'box-Tojjar',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 4,
    content: {
      country: Country.UnitedArabEmirates,
      coverImage: 'img/Case_study_-_an_MVP_for_a_Ionoview.jpg',
      clientName: 'IonoView',
      clientLogo: 'logoIono',
      title:
        'How to scale a monolithic MVP application without losing business?',
      tags: ['php', 'symfony', 'agile', 'microservices', 'consulting'],
      industry: [Industry.SAAS],
      service: [Service.FCPD, Service.DevTeam, Service.PDD],
      subtitle: [''],
      description: [
        'The Client had already tested his MVP on real users and wanted to scale the product, however due to technological and business limitation he was unable to do it in-house. Acting as solution and process consultants as well as development experts, Boldare team guided the client through the transition, while keeping the app fully operational.',
      ],
      productStage: [ProductStage.Scaling],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {
      link: '/work/case-study-ionoview/',
    },
    id: 'box-Iono',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 5,
    content: {
      country: Country.Poland,
      coverImage: 'img/case-study-pso-web-app.jpg',
      clientName: 'PlatformaOpon.pl',
      clientLogo: 'logoPSO',
      title:
        'How to speed up content moderation for e-commerce with machine learning',
      tags: ['php', 'automation', 'AWS', 'Symfony2', 'ML'],
      industry: [Industry.ECommerce],
      service: [Service.MachineLearning],
      subtitle: [''],
      description: [
        'Machine learning (ML) has gone mainstream. The use of this technology – a consequence of AI development – is becoming more and more common in the world of business. Self-learning systems are ideally suited to analyzing large volumes data and making predictions.',
      ],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/machine-learning-content-moderation/',
    },
    id: 'box-PSO',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 5,
    content: {
      country: Country.Poland,
      coverImage: 'img/case-study-boldare-web-app.jpg',
      clientName: 'Boldare',
      clientLogo: logoBldr,
      title: 'How to test a business idea using lean startup approach.',
      tags: ['Lean Startup', 'MVP', 'ReactJS'],
      industry: [Industry.ITServices],
      service: [Service.FCPD, Service.DevTeam, Service.PDD],
      subtitle: [''],
      description: [
        '"The team delivered exceptional work allowing us to test a business idea in just 6 weeks, saving us hundreds of thousands of dollars of development hours and significantly reducing the risk of poor investment." Piotr Majchrzak, CEO, XSolve',
      ],
      productStage: [ProductStage.MVP],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-boldare/',
    },
    id: 'box-Bldr',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 4,
    content: {
      country: Country.Israel,
      coverImage: 'img/work-practitest.jpg',
      clientName: 'PractiTest',
      clientLogo: 'logoPractiTest',
      title: 'Remote web design team dedicated to all company needs',
      tags: ['HTML5', 'CSS3', 'UX/UI', 'Israel'],
      industry: [Industry.B2bServices],
      service: [Service.PDD],
      subtitle: [''],
      description:
        'This startup came to Boldare looking for a product website for a SaaS test management tool that converts data to QA intelligence.Our team created an intuitive human-centered experience for a demanding target persona: QA Engineers.',
      productStage: [ProductStage.PMF],
      region: [Region.MiddleEastAndAfrica],
    },
    settings: {
      link: '/work/case-study-practitest/',
    },
    id: 'box-practi-test',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 4,
    content: {
      country: Country.Switzerland,
      coverImage: 'img/case-study-eres-yachting-mockups.jpg',
      clientName: 'Eres',
      clientLogo: 'logoEres',
      title:
        'How to successfully transition from a physical business to digital service.',
      tags: ['workshops', 'webdesign', 'consulting'],
      industry: [Industry.Travel],
      service: [Service.DigitalTransformation, Service.PDD],
      subtitle: [''],
      description: [
        '“Boldare stuck to our budget and timeline. If anything took longer than anticipated, it was immediately communicated to us. They were always transparent.” Ursula Leitner, Marketing Manager, Eres Yachting',
      ],
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-eres-yachting-booking-platform/',
    },
    id: 'box-eres',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 3,
    content: {
      country: Country.Poland,
      coverImage: 'img/work-planet-escape.jpg',
      clientName: 'Planet Escape',
      clientLogo: 'logoPlanetEscape',
      title: `Discovering the world is life's passion`,
      tags: ['JavaScript', 'Symfony', 'UX/UI', 'Poland'],
      industry: [Industry.Travel],
      service: [Service.PDD],
      subtitle: [''],
      description:
        'A high-end, bespoke travel agency needed to digitize their customer experience. Boldare’s team created a visually gripping website with a seamless booking process to meet the specific needs of the agency’s premium clients. ',
      productStage: [ProductStage.PMF],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-planet-escape/',
    },
    id: 'box-planet-escape',
  },
  {
    type: BOX_TYPE.CASE_STUDY,
    pinned: false,
    priority: 5,
    content: {
      country: Country.Italy,
      coverImage: 'img/work-showbiz.jpg',
      clientName: 'ShowBiz',
      clientLogo: 'logoShowbiz',
      title: 'ShowbizModels - fancy app for world-wide modeling agency',
      tags: ['Symfony', 'JavaScript', 'MVP', 'Italy'],
      industry: [Industry.SAAS],
      service: [Service.DevTeam],
      subtitle: [''],
      description:
        'We created a stable and fast application that can be scaled. Thanks to technical, UX, and design changes, the Client noticed an increase in the number of registered users.',
      productStage: [ProductStage.Scaling],
      region: [Region.Europe],
    },
    settings: {
      link: '/work/case-study-showbiz/',
    },
    id: 'box-showbiz-models',
  },
];
