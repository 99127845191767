import React from 'react';
import IconSVG from 'components/IconSVG';
import PropTypes from 'prop-types';
import './ButtonLink.scss';

const ButtonLink = ({ category }) => {
  if (!category.url) return null;
  return (
    <div className={'button-link'}>
      <a href={category.url} className={'button-link__link'}>
        <button className={'button-link__button'}>
          {category.name}
          <IconSVG name={'arrow-right'} color={'#242424'} size={'small'} />
        </button>
        <div className={'button-link__bg'} />
      </a>
    </div>
  );
};

ButtonLink.defaultProps = {
  category: {},
};

ButtonLink.propTypes = {
  category: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.string,
        image: PropTypes.string,
        alt: PropTypes.string,
      }),
    ),
    url: PropTypes.string,
  }),
};

export default ButtonLink;
